<template>
  <b-container fluid class="movement-route-detail-page py-6">
    <b-overlay :show="loading">
      <b-row>
        <b-col cols="2">
          <div
            class="card card-custom gutter-b movement-route-detail-page__left-panel"
          >
            <div class="card-body p-0">
              <div class="movement-route-detail-page__left-panel__title p-4">
                <span>{{ roadmapInfo.name }}</span>
              </div>
              <div
                class="movement-route-detail-page__left-panel__actions pl-4 pr-4"
              >
                <b-button size="sm" class="btn-edit mr-4" @click="editRoadMap">
                  <span class="svg-icon svg-icon-sm mr-0">
                    <inline-svg
                      class="svg-icon mr-0"
                      src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                    />
                  </span>
                </b-button>
                <span class="status" :class="getStatus(roadmapInfo.status)">
                  {{ getStatus(roadmapInfo.status) }}
                </span>
              </div>
              <div class="movement-route-detail-page__left-panel__information">
                <b-container>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Mã lộ trình</div>
                      <div>{{ roadmapInfo.code }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">
                        Số {{ roadmapInfo.type === 1 ? 'ngày' : 'tuần' }}
                      </div>
                      <div
                        class="menu-icon svg-icon svg-icon-sm d-flex align-items-center"
                      >
                        <inline-svg
                          class="svg-icon mr-1"
                          src="/media/svg/icons/Neolex/Basic/calendar.svg"
                        />
                        {{ roadmapInfo.day }}
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Từ khóa</div>
                      <div>
                        {{ roadmapInfo.tags && roadmapInfo.tags.join(', ') }}
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Mô tả</div>
                      <div>{{ roadmapInfo.description }}</div>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="10">
          <div
            v-if="exerciseList && exerciseList.length > 0"
            class="movement-route-detail-page__right-panel"
          >
            <b-row>
              <div
                class="p-0 movement-route-detail-page__right-panel__card-container"
                cols="2"
                v-for="(item, index) in exerciseList"
                :key="index"
              >
                <b-card
                  class="movement-route-detail-page__right-panel__card w-100 h-100"
                >
                  <div class="d-flex">
                    <div
                      class="movement-route-detail-page__right-panel__card__number d-flex justify-content-center align-items-center"
                    >
                      {{ index + 1 }}
                    </div>
                    <div
                      class="movement-route-detail-page__right-panel__card__title d-flex align-items-center"
                    >
                      <span class="ellipsis">{{ item.name }}</span>
                    </div>
                    <div
                      class="movement-route-detail-page__right-panel__card__action-dropdown"
                    >
                      <action-dropdown
                        v-if="item.isNull"
                        :value="item"
                        :show_copy="false"
                        :show_view="false"
                        :show_delete="false"
                        :show_edit="false"
                      >
                        <template>
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                          >
                            <a
                              class="navi-link text-primary"
                              @click="onClickCreateLesson(item)"
                            >
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                                />
                              </span>
                              <span class="navi-text text-primary">
                                Thêm mới bài tập
                              </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-divider />
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                            @click="addExercise(item)"
                          >
                            <a class="navi-link text-primary">
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/plus.svg"
                                />
                              </span>
                              <span class="navi-text text-primary">
                                Thêm bài tập sau
                              </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-divider />
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                            @click="chooseDayOff(item)"
                          >
                            <a class="navi-link text-primary">
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/check-square.svg"
                                />
                              </span>
                              <span class="navi-text text-primary">
                                Chọn là ngày nghỉ
                              </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-divider />
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                            @click="addDayOff(item)"
                          >
                            <a class="navi-link text-primary">
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/General/plus-day-off.svg"
                                />
                              </span>
                              <span class="navi-text text-primary">
                                Thêm ngày nghỉ sau
                              </span>
                            </a>
                          </b-dropdown-text>
                        </template>
                      </action-dropdown>
                      <action-dropdown
                        v-else-if="item.isBlank"
                        :value="item"
                        :show_copy="false"
                        :show_view="false"
                        :show_delete="false"
                        :show_edit="false"
                      >
                        <template>
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                            @click="deleteExercise(item)"
                          >
                            <a class="navi-link text-danger">
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                                />
                              </span>
                              <span class="navi-text text-danger">
                                Xóa bài tập
                              </span>
                            </a>
                          </b-dropdown-text>
                        </template>
                      </action-dropdown>
                      <action-dropdown
                        v-else
                        :value="item"
                        :show_copy="false"
                        :show_view="false"
                        :show_delete="false"
                        :show_edit="false"
                      >
                        <template>
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                          >
                            <a
                              class="navi-link text-primary"
                              @click="onClickViewDetailLesson(item)"
                            >
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/info.svg"
                                />
                              </span>
                              <span class="navi-text text-primary">
                                Xem chi tiết bài tập
                              </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-divider />
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                          >
                            <a
                              class="navi-link text-primary"
                              @click="onClickEditLesson(item)"
                            >
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                                />
                              </span>
                              <span class="navi-text text-primary">
                                Chỉnh sửa bài tập
                              </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-divider />
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                            @click="addExercise(item)"
                          >
                            <a class="navi-link text-primary">
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/plus.svg"
                                />
                              </span>
                              <span class="navi-text text-primary">
                                Thêm bài tập sau
                              </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-divider />
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                            @click="deleteExercise(item)"
                          >
                            <a class="navi-link text-danger">
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                                />
                              </span>
                              <span class="navi-text text-danger">
                                Xóa bài tập
                              </span>
                            </a>
                          </b-dropdown-text>
                        </template>
                      </action-dropdown>
                    </div>
                  </div>
                  <div class="mb-4 p-4 flex-grow-1">
                    <div
                      v-if="!item.isBlank"
                      class="movement-route-detail-page__right-panel__card__description ellipsis--line-clamp-4"
                    >
                      {{ item.description }}
                    </div>
                    <div v-else class="d-flex justify-content-center">
                      <span class="icon-day-off">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/General/day-off.svg"
                        />
                      </span>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end p-4">
                    <div
                      v-if="item.isFree"
                      class="movement-route-detail-page__right-panel__card__free-button"
                    >
                      Free
                    </div>
                  </div>
                </b-card>
              </div>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
    <ModalMovementRoute
      v-if="showModal"
      :type="modalType"
      :id="agendaId"
      @reset="resetModal"
      @change-status="getData"
    />
  </b-container>
</template>

<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
export default {
  name: 'MovementRouteDetail',
  components: {
    ModalMovementRoute: () => import('./components/Modal.vue'),
  },
  data() {
    return {
      url: {
        toggleFree: '/ExerciseMovement/{id}/ToggleFree',
        detail: '/Agenda',
        delete: '/ExerciseMovement/{id}',
        create: '/ExerciseMovement',
      },
      roadmapInfo: {
        code: null,
        name: null,
        day: null,
        tags: [],
        description: null,
        status: null,
        classify: null,
      },
      exerciseList: [],
      showModal: false,
      loading: false,
    };
  },
  computed: {
    agendaId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    onClickCreateLesson(item) {
      this.$router.push({
        name: 'movement_route_lesson_create',
        params: {
          id: this.agendaId,
          day: item.day,
        },
      });
    },
    onClickEditLesson(item) {
      this.$router.push({
        name: 'movement_route_lesson_edit',
        params: {
          id: this.agendaId,
          lessonId: item.id,
        },
      });
    },
    onClickViewDetailLesson(item) {
      this.$router.push({
        name: 'movement_route_lesson_detail',
        params: {
          id: this.agendaId,
          lessonId: item.id,
        },
      });
    },
    async getData(loading = true) {
      try {
        loading && (this.loading = true);
        const { data, meta, error } = await this.$api.get(
          `Agenda/${this.agendaId}`,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        let exerciseArr = data.exerciseMovements || [];
        this.exerciseList = this.generateNullExerciseList(data.day);
        exerciseArr.length &&
          exerciseArr.forEach((exercise) => {
            this.exerciseList[exercise.day - 1] = exercise;
          });

        this.roadmapInfo = { ...data };
        this.roadmapInfo.tags = data.selectTags.map((tag) => tag.name);
        this.roadmapInfo.code = data.code;
        this.roadmapInfo.name = data.name;
        this.roadmapInfo.day = data.day;
        this.roadmapInfo.description = data.description;

        this.roadmapInfo.status = data.status;
        // this.roadmapInfo.classify = data.classify;
      } catch (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        loading && (this.loading = false);
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';
        default:
          return 'draft';
      }
    },
    generateNullExerciseList(day) {
      let arr = [];
      for (let i = 0; i < day; i++) {
        const exercise = {
          code: null,
          name: null,
          isFree: false,
          description: null,
          day: i + 1,
          isNull: true,
        };
        arr.push(exercise);
      }
      return arr;
    },
    editRoadMap() {
      this.modalType = MODAL_TYPE.EDIT;
      this.showModal = true;
      this.$store.commit(`context/setModal`, true);
    },
    resetModal() {
      this.showModal = false;
      this.modalType = null;
    },
    async deleteExercise(item) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.delete(
          this.url.delete.replace('{id}', item.id),
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        await this.getData(false);
      } catch (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async chooseDayOff(item) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.post('ExerciseMovement/Blank', {
          isBlank: true,
          agendaId: this.agendaId,
          day: item.day,
        });
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        await this.getData(false);
      } catch (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async addDayOff(item) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.post('ExerciseMovement/Blank', {
          isBlank: true,
          agendaId: this.agendaId,
          day: item.day + 1,
          isInserted: true,
        });
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        await this.getData(false);
      } catch (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async addExercise(item) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.post(
          '/ExerciseMovement/Blank',
          {
            IsInserted: true,
            day: item.day + 1,
            agendaId: this.agendaId,
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        await this.getData(false);
      } catch (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.movement-route-detail-page {
  .movement-route-detail-page__left-panel {
    height: 100%;

    .movement-route-detail-page__left-panel__title {
      span {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .movement-route-detail-page__left-panel__actions {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  .movement-route-detail-page__right-panel {
    height: 100%;
    padding: 12px;

    .movement-route-detail-page__right-panel__card-container {
      width: calc(100% / 7);
    }

    .movement-route-detail-page__right-panel__card {
      aspect-ratio: 1;

      .card-body {
        padding: 0;
        display: flex;
        flex-direction: column;
      }

      .movement-route-detail-page__right-panel__card__number {
        background-color: #008479;
        margin-right: 6px;
        color: #ffffff;
        font-weight: bold;
        flex: 0 0 42px;
      }

      .movement-route-detail-page__right-panel__card__title {
        color: #008479;
        flex-grow: 1;
        font-weight: bold;
        min-width: 0;
      }

      .movement-route-detail-page__right-panel__card__free-button {
        position: relative;
        background: #fdb913;
        color: white;
        font-size: 12px;
        text-align: center;
        display: inline-block;
        padding: 4px 12px;
      }

      .movement-route-detail-page__right-panel__card__free-button:before,
      .movement-route-detail-page__right-panel__card__free-button:after {
        content: '';
        position: absolute;
        top: 10px;
        height: 4.8px;
        width: 2.4px;
        border: 2px solid white;
      }

      .movement-route-detail-page__right-panel__card__free-button:before {
        left: -2px;
        border-radius: 0 4px 4px 0;
      }

      .movement-route-detail-page__right-panel__card__free-button:after {
        right: -2px;
        border-radius: 4px 0 0 4px;
      }

      .movement-route-detail-page__right-panel__card__action-dropdown {
        flex: 0 0 32px;
      }
    }
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis--line-clamp-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }

  &.draft {
    background: #888c9f;
  }
}

.btn-edit {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}

.icon-day-off {
  width: 80px;
  height: 60px;
}
</style>
